export default {
  'fourOFour.error.title': 'Error 404',
  'fourOFour.error.description': 'Maybe, this page was never created? We are just in simulation managed by aliens?',
  'website.title': 'Vivense Shop',
  'out.of.stock': 'Out of stock',
  'in.stock': 'In stock',
  products: 'Products',
  description: 'Description',
  'add.to.cart': 'Add to cart',
  'out.of.stock.message': 'This product couldn\'t have supplied yet.',
};
